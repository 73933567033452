export default function ShieldIcon(props?: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0001 4.86676L4.75014 8.11676C4.75014 8.11676 4.00014 19.3668 12.0001 19.3668C20.0001 19.3668 19.2501 8.11676 19.2501 8.11676L12.0001 4.86676Z"
        fill="currentColor"
        fillOpacity="0.2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 12.8668L11 14.3668L14.25 9.86676"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
