import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { type ChainData } from '@/shared/assets/chains';
import { deduplicater } from '@/shared/utils';
import { integrationManager } from '../integrations';

interface ChainsData {
  chains: ChainData[];
  isLoading: boolean;
  error: boolean;
}

const deduplicateChains = deduplicater<ChainData>((c) => c.id);

const useChains = (sourceChain?: ChainData): ChainsData => {
  const {
    data: chainflipChains,
    error: chainflipError,
    isLoading: chainflipLoading,
  } = useQuery({
    queryKey: ['CHAINFLIP_CHAINS', sourceChain?.id],
    queryFn: async () => {
      const cf = integrationManager.getIntegration('chainflip');

      if (sourceChain) {
        return cf.getDestinationChains(sourceChain.id);
      }

      return cf.getChains();
    },
  });

  const {
    data: squidChains,
    error: squidError,
    isLoading: squidLoading,
  } = useQuery({
    queryKey: ['SQUID_CHAINS', sourceChain?.id],
    queryFn: async () => {
      const squid = integrationManager.getIntegration('squid');

      if (sourceChain) {
        return squid.getDestinationChains(sourceChain.id);
      }

      return squid.getChains();
    },
  });

  const chains = useMemo(
    () => deduplicateChains([...(chainflipChains ?? []), ...(squidChains ?? [])]),
    [chainflipChains, squidChains],
  );

  return {
    chains,
    isLoading: chainflipLoading || squidLoading,
    error: Boolean(chainflipError || squidError), // TODO: handle error view
  };
};

export default useChains;
