import { useCallback, useRef } from 'react';

type Validator = () => boolean;

export default function useFormValidation<K extends string>(validators: Record<K, Validator>) {
  const formValidation = useRef<Record<K, Validator>>(validators);

  const validateForm = useCallback(
    () =>
      Object.values<Validator>(formValidation.current).reduce(
        // make sure to call cb first so it doesn't short circuit
        (acc, cb) => cb() && acc,
        true,
      ),
    [],
  );

  const setValidator = useCallback(<T extends K>(key: T, validator: Validator) => {
    formValidation.current[key] = validator;
  }, []);

  return { setValidator, validateForm };
}
