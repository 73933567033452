import { useCallback, useMemo } from 'react';
import { toast as rawToast } from 'sonner';

type ToastArgs = Parameters<typeof rawToast.success>;
type ToasterFn = (...params: ToastArgs) => void;
type ToastType = 'success' | 'error' | 'loading' | 'info';
type Toast = {
  [key in ToastType]: ToasterFn;
};

type DismissableToast = Toast & {
  dismiss: () => void;
};

const closeBtnClassnames = `[&>button]:bg-cf-gray-3-5 [&>button]:border-cf-gray-4
[&>button]:hover:border-cf-gray-4 [&>button]:hover:bg-cf-gray-3-5`;
export const defaultToastClassnames = `text-cf-white bg-cf-gray-3 font-medium border-cf-gray-4 ${closeBtnClassnames}`;

export default function useToast(name: string): DismissableToast {
  const invoke = useCallback(
    (type: 'success' | 'error' | 'loading' | 'info', [message, data]: ToastArgs) => {
      rawToast[type](message, {
        duration: type === 'loading' ? Infinity : 5000,
        description: undefined,
        className: defaultToastClassnames,
        ...data,
        id: name,
      });
    },
    [],
  );

  const toast: DismissableToast = useMemo(
    () => ({
      loading: (...params) => invoke('loading', params),
      success: (...params) => invoke('success', params),
      error: (...params) => invoke('error', params),
      dismiss: () => rawToast.dismiss(name),
      info: (...params) => invoke('info', params),
    }),
    [],
  );

  return toast;
}
