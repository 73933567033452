import { AnimatePresence, motion } from 'framer-motion';
import { DisableSSR } from '@/shared/components';
import { useMobile } from '@/shared/hooks';
import { AnimateHeightChange } from '../components/AnimateHeightChange';
import { BraggingCard } from '../components/SwapPage/BraggingCard/BraggingCard';
import RouteListV2 from '../components/SwapPage/RouteList/RouteList';
import SwapCard from '../components/SwapPage/SwapCard/SwapCard';
import { RoutesProvider } from '../hooks/useRoutes';
import useSwapRequestStore, { selectShowRouteList } from '../hooks/useSwapRequestStore';
import { defaultAnimationProps } from '../utils/consts';

export default function Index(): JSX.Element {
  const isMobile = useMobile();
  const showRouteList = useSwapRequestStore(selectShowRouteList);

  return (
    <DisableSSR>
      <RoutesProvider>
        <AnimateHeightChange>
          {isMobile ? (
            <div className="flex flex-col space-y-6 md:hidden" key="mobile">
              <SwapCard />
              <AnimatePresence mode="wait">
                {showRouteList && (
                  <motion.div key="route-list" {...defaultAnimationProps}>
                    <RouteListV2 />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ) : (
            <div className="hidden justify-center space-x-6 md:flex" key="desktop">
              <SwapCard />
              <AnimatePresence mode="wait">
                {showRouteList ? (
                  <motion.div key="route-list" {...defaultAnimationProps}>
                    <RouteListV2 />
                  </motion.div>
                ) : (
                  <motion.div key="bragging-card" {...defaultAnimationProps}>
                    <BraggingCard />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </AnimateHeightChange>
      </RoutesProvider>
    </DisableSSR>
  );
}
