import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { TokenAmount } from '@/shared/utils';
import { chainflipAssetMap } from '@/shared/utils/env';
import Lottie from '@/shared/utils/Lottie';
import TokenInputField from './TokenInputField';
import switchJSON from '../../assets/lotties/switch.json';
import useSwapRequestStore from '../../hooks/useSwapRequestStore';

const queryParamToToken = (str: string | string[] | undefined) => {
  if (typeof str === 'string') {
    for (const token of Object.values(chainflipAssetMap)) {
      if (token.chainflipId.toLowerCase() === str.toLowerCase()) return token;
    }
  }
  return null;
};

export default function TokenFields({
  setValidator,
}: {
  setValidator: (name: 'srcAmount' | 'destAmount', cb: () => boolean) => void;
}) {
  const router = useRouter();
  const srcToken = useSwapRequestStore((state) => state.srcToken);
  const srcAmount = useSwapRequestStore((state) => state.srcAmount);
  const destToken = useSwapRequestStore((state) => state.destToken);
  const destAmount = useSwapRequestStore((state) => state.selectedRoute?.destAmount);
  const setSrcToken = useSwapRequestStore((state) => state.setSrcToken);
  const setSrcAmount = useSwapRequestStore((state) => state.setSrcAmount);
  const setDestToken = useSwapRequestStore((state) => state.setDestToken);

  const [switchState, setSwitchState] = useState<'fadeIn' | 'fadeOut' | null>(null);

  useEffect(() => {
    const { srcAsset, destAsset, ...remainingQuery } = router.query;
    const src = queryParamToToken(srcAsset);
    if (src) setSrcToken(src);
    const dest = queryParamToToken(destAsset);
    if (dest) setDestToken(dest);

    // remove asset params from url after they are set to the state
    if (src || dest) {
      router.replace({ pathname: router.pathname, query: remainingQuery });
    }
  }, [router.query.srcAsset, router.query.destAsset]);

  const onTokenSwitch = useCallback(() => {
    if (switchState === 'fadeIn') {
      if (destAmount?.gt(0)) {
        setSrcAmount(new TokenAmount(destAmount?.value ?? 0, destToken?.decimals));
      }
      setSrcToken(destToken);
      setDestToken(srcToken);
    }
  }, [srcToken, destToken, switchState, destAmount]);

  useEffect(onTokenSwitch, [switchState]);

  const onSwitchEnd = useCallback(() => {
    if (switchState === 'fadeOut') {
      setSwitchState('fadeIn');
    } else if (switchState === 'fadeIn') {
      setSwitchState(null);
    }
  }, [switchState]);

  return (
    <div className="flex w-full flex-col items-center gap-8 md:gap-4">
      <TokenInputField
        label="Deposit"
        token={srcToken}
        value={srcAmount}
        onChange={setSrcAmount}
        switchState={switchState}
        onSwitchEnd={onSwitchEnd}
        setInputAmountValidator={(cb) => setValidator('srcAmount', cb)}
      />
      <div
        className="rounded-md border border-cf-gray-4 bg-cf-gray-3-5 px-1.5 pt-1.5 transition duration-100 ease-out hover:border-cf-gray-5 hover:bg-cf-gray-4"
        onClick={(e) => {
          if (switchState !== null) {
            e.preventDefault();
          } else {
            setSwitchState('fadeOut');
          }
        }}
      >
        <Lottie
          as="button"
          animationData={switchJSON}
          autoplay={false}
          loop={false}
          className="h-[24px] w-[24px] rotate-90 stroke-white"
        />
      </div>
      <TokenInputField
        label="Receive"
        token={destToken}
        value={srcAmount && destAmount}
        readonly
        switchState={switchState}
        onSwitchEnd={onSwitchEnd}
        setInputAmountValidator={(cb) => setValidator('destAmount', cb)}
      />
    </div>
  );
}
