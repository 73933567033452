const ActiveBoltIcon = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_969_119208)">
      <g filter="url(#filter0_d_969_119208)">
        <path
          d="M10.75 13.25H6.75L13.25 4.75V10.75H17.25L10.75 19.25V13.25Z"
          fill="#FF33AF"
          fillOpacity="0.29"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_969_119208)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4905 4.03965C13.7951 4.14275 14 4.42851 14 4.75005V10H17.25C17.5353 10 17.796 10.162 17.9224 10.4178C18.0488 10.6736 18.0191 10.979 17.8458 11.2056L11.3458 19.7056C11.1505 19.961 10.8141 20.0635 10.5095 19.9604C10.205 19.8573 10 19.5716 10 19.25V14H6.75001C6.46466 14 6.20404 13.8381 6.07762 13.5823C5.95121 13.3265 5.98091 13.0211 6.15424 12.7945L12.6542 4.29446C12.8496 4.03905 13.1859 3.93655 13.4905 4.03965ZM8.26769 12.5H10.75C11.1642 12.5 11.5 12.8358 11.5 13.25V17.0346L15.7323 11.5H13.25C12.8358 11.5 12.5 11.1643 12.5 10.75V6.96549L8.26769 12.5Z"
          fill="url(#paint0_linear_969_119208)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_969_119208"
        x="-0.85"
        y="-2.85"
        width="25.7"
        height="29.7"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3.8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.2 0 0 0 0 0.686275 0 0 0 0.54 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_969_119208" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_969_119208"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_969_119208"
        x="-1.6"
        y="-3.6"
        width="27.2"
        height="31.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3.8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.2 0 0 0 0 0.686275 0 0 0 0.54 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_969_119208" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_969_119208"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_969_119208"
        x1="12"
        y1="4.75005"
        x2="12"
        y2="19.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF33AF" />
        <stop offset="0.302083" stopColor="#C22E88" />
        <stop offset="0.677083" stopColor="#FF75C9" />
        <stop offset="1" stopColor="#FF33AF" />
      </linearGradient>
      <clipPath id="clip0_969_119208">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ActiveBoltIcon;
