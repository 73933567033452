import React from 'react';

export const GridFrame = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="430"
    height="309"
    viewBox="0 0 430 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2510_83525)">
      <g opacity="0.5">
        <g opacity="0.5" filter="url(#filter0_f_2510_83525)">
          <path
            d="M172.34 60.687C167.917 55.3273 168.143 47.5224 172.867 42.4269L301.679 -96.5021C307.375 -102.645 317.175 -102.376 322.525 -95.9299L352.906 -59.3255C358.178 -52.9724 356.769 -43.4483 349.882 -38.8949L192.252 65.3234C186.334 69.2362 178.418 68.0529 173.902 62.5807L172.34 60.687Z"
            fill="url(#paint0_linear_2510_83525)"
          />
        </g>
        <g opacity="0.68" filter="url(#filter1_f_2510_83525)">
          <path
            d="M173.868 173.456C174.68 174.415 176.105 174.56 177.094 173.785L363.937 27.4193C366.835 25.1491 367.304 20.9421 364.978 18.0889V18.0889C362.657 15.2423 358.454 14.8491 355.646 17.2157L174.145 170.155C173.154 170.989 173.03 172.469 173.868 173.456V173.456Z"
            fill="url(#paint1_linear_2510_83525)"
          />
        </g>
        <g opacity="0.6">
          <g opacity="0.4" filter="url(#filter2_f_2510_83525)">
            <path
              d="M263.284 57.4141C260.337 53.837 260.555 48.6145 263.791 45.2958L370.213 -63.855C373.979 -67.7177 380.262 -67.4662 383.707 -63.315L402.88 -40.2141C406.285 -36.1121 405.442 -29.9741 401.058 -26.9416L275.896 59.6339C272.013 62.3197 266.72 61.5833 263.717 57.9396L263.284 57.4141Z"
              fill="url(#paint2_linear_2510_83525)"
            />
          </g>
          <g opacity="0.4" filter="url(#filter3_f_2510_83525)">
            <path
              d="M231.503 43.9536C230.604 43.0022 230.613 41.5119 231.524 40.572L330.42 -61.4779C333.058 -64.2009 337.428 -64.1979 340.063 -61.4714V-61.4714C342.687 -58.7553 342.554 -54.4087 339.768 -51.8587L234.932 44.0785C233.946 44.9803 232.42 44.9247 231.503 43.9536V43.9536Z"
              fill="url(#paint3_linear_2510_83525)"
            />
          </g>
          <g opacity="0.4" filter="url(#filter4_f_2510_83525)">
            <path
              d="M287.442 96.1074C286.543 95.156 286.553 93.6657 287.463 92.7258L386.359 -9.32415C388.997 -12.0471 393.367 -12.0441 396.002 -9.31756V-9.31756C398.627 -6.60151 398.493 -2.25493 395.707 0.295063L290.871 96.2324C289.886 97.1342 288.359 97.0786 287.442 96.1074V96.1074Z"
              fill="url(#paint4_linear_2510_83525)"
            />
          </g>
          <g opacity="0.5" filter="url(#filter5_f_2510_83525)">
            <path
              d="M258.594 84.8899C259.187 85.5901 260.228 85.6961 260.951 85.13L397.438 -21.7893C399.555 -23.4477 399.898 -26.5209 398.199 -28.6051V-28.6051C396.503 -30.6846 393.433 -30.9718 391.381 -29.243L258.796 82.4781C258.073 83.0876 257.982 84.1686 258.594 84.8899V84.8899Z"
              fill="url(#paint5_linear_2510_83525)"
            />
          </g>
        </g>
      </g>
      <mask
        id="mask0_2510_83525"
        maskUnits="userSpaceOnUse"
        x="-150"
        y="0"
        width="695"
        height="309"
      >
        <g opacity="0.39">
          <rect
            x="-34.0535"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.2426"
            y="38.5859"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.02"
          />
          <rect
            x="-34.0535"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.0535"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.0535"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.0535"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.2426"
            y="192.929"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.05"
          />
          <rect
            x="-34.0535"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.0535"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="-34.0535"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.34306"
            y="77.1714"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.02"
          />
          <rect
            x="4.53221"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="4.53221"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="43.1179"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.5148"
            y="115.757"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.02"
          />
          <rect
            x="81.7039"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="81.7039"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.1"
            y="38.5859"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.01"
          />
          <rect
            x="120.29"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="120.29"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.686"
            y="77.1714"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.05"
          />
          <rect
            x="158.875"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="158.875"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.272"
            y="154.343"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.02"
          />
          <rect
            x="197.461"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="197.461"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect x="235.858" width="38.5857" height="38.5857" fill="white" fillOpacity="0.05" />
          <rect
            x="236.047"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="236.047"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="274.633"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect x="313.03" width="38.5857" height="38.5857" fill="white" fillOpacity="0.02" />
          <rect
            x="313.219"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="313.219"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="351.804"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.201"
            y="192.929"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.02"
          />
          <rect
            x="390.391"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="390.391"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="0.189146"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="38.7751"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="77.3605"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="115.946"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.787"
            y="154.343"
            width="38.5857"
            height="38.5857"
            fill="white"
            fillOpacity="0.02"
          />
          <rect
            x="428.976"
            y="154.532"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="193.118"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="231.704"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
          <rect
            x="428.976"
            y="270.289"
            width="38.2074"
            height="38.2074"
            stroke="white"
            strokeOpacity="0.16"
            strokeWidth="0.378292"
          />
        </g>
      </mask>
      <g mask="url(#mask0_2510_83525)">
        <rect x="-150" width="398" height="317" fill="url(#paint6_radial_2510_83525)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_2510_83525"
        x="112.712"
        y="-157.397"
        width="299.847"
        height="281.477"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="28.2275" result="effect1_foregroundBlur_2510_83525" />
      </filter>
      <filter
        id="filter1_f_2510_83525"
        x="150.939"
        y="-6.70525"
        width="237.892"
        height="203.362"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="11.1869" result="effect1_foregroundBlur_2510_83525" />
      </filter>
      <filter
        id="filter2_f_2510_83525"
        x="247.249"
        y="-80.5593"
        width="171.687"
        height="155.768"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6.9808" result="effect1_foregroundBlur_2510_83525" />
      </filter>
      <filter
        id="filter3_f_2510_83525"
        x="216.873"
        y="-77.4797"
        width="139.036"
        height="136.161"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6.9808" result="effect1_foregroundBlur_2510_83525" />
      </filter>
      <filter
        id="filter4_f_2510_83525"
        x="272.812"
        y="-25.3259"
        width="139.036"
        height="136.161"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6.9808" result="effect1_foregroundBlur_2510_83525" />
      </filter>
      <filter
        id="filter5_f_2510_83525"
        x="250.088"
        y="-38.473"
        width="157.291"
        height="132.067"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4.05" result="effect1_foregroundBlur_2510_83525" />
      </filter>
      <linearGradient
        id="paint0_linear_2510_83525"
        x1="341.443"
        y1="-101.534"
        x2="172.935"
        y2="75.8084"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0710876" stopColor="white" stopOpacity="0.44" />
        <stop offset="0.703125" stopColor="white" stopOpacity="0.22" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2510_83525"
        x1="382.743"
        y1="7.0902"
        x2="162.884"
        y2="9.25921"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0710876" stopColor="#424242" />
        <stop offset="0.677083" stopColor="#424242" stopOpacity="0.26" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2510_83525"
        x1="397.805"
        y1="-68.6899"
        x2="269.572"
        y2="74.098"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0710876" stopColor="white" />
        <stop offset="0.677083" stopColor="#5F5F5F" stopOpacity="0.29" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2510_83525"
        x1="347.272"
        y1="-72.3294"
        x2="292.428"
        y2="73.8995"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0710876" stopColor="white" />
        <stop offset="0.677083" stopColor="#5F5F5F" stopOpacity="0.29" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2510_83525"
        x1="403.211"
        y1="-20.1756"
        x2="348.367"
        y2="126.053"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0710876" stopColor="white" />
        <stop offset="0.677083" stopColor="#5F5F5F" stopOpacity="0.29" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2510_83525"
        x1="411.175"
        y1="-36.6396"
        x2="250.57"
        y2="-35.0552"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0710876" stopColor="white" />
        <stop offset="0.677083" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint6_radial_2510_83525"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(49) rotate(90) scale(317 398)"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_2510_83525">
        <rect width="430" height="309" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
