import { useMemo } from 'react';
import { getChainflipId } from '@chainflip/sdk/swap';
import { assetConstants, type ChainflipAsset } from '@chainflip/utils/chainflip';
import { useQuery } from '@tanstack/react-query';
import { isChainflipTokenOrChain, type Token } from '@/shared/assets/tokens';
import { CHAINFLIP_BOOST_LIQUIDITY } from '@/shared/queryKeys';
import { integrationManager } from '../integrations';

export const useBoostPoolsDepth = ({
  token,
  tier,
}: {
  token?: Token;
  tier?: number;
} = {}) => {
  const filter = useMemo(() => {
    if (!isChainflipTokenOrChain(token)) return {};

    return {
      ...(token && {
        chainAsset: {
          asset: assetConstants[token.chainflipId].rpcAsset,
          chain: token.chain.chainflipId,
        },
      }),
      ...(tier && {
        tier,
      }),
    };
  }, [token, tier]);

  const { data: poolDepths, isLoading } = useQuery({
    queryKey: [CHAINFLIP_BOOST_LIQUIDITY],
    queryFn: async () =>
      integrationManager.getIntegration('chainflip').sdk.getBoostLiquidity(filter),
    refetchInterval: 10_000,
  });

  const maxBoostSwapAmounts = useMemo(
    () =>
      poolDepths?.reduce(
        (amounts, poolDepth) => {
          const internalAsset = getChainflipId(poolDepth);

          return {
            ...amounts,
            ...(internalAsset && {
              [internalAsset]: (amounts[internalAsset] ?? BigInt(0)) + poolDepth.availableAmount,
            }),
          };
        },
        {} as Record<ChainflipAsset, bigint>,
      ),
    [poolDepths],
  );

  return {
    poolDepths,
    maxBoostSwapAmounts,
    isLoading,
  };
};
