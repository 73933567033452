import { useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize } from '@chainflip/utils/string';
import { useWallet } from '@solana/wallet-adapter-react';
import classNames from 'classnames';
import { useAccount } from 'wagmi';
import { isEvmChain, isSolanaChain } from '@/shared/assets/chains';
import { validateChainAddress } from '@/shared/assets/chains/addressValidation';
import {
  chainflipAssetMonochromaticChainLogo,
  chainTransparentLogo,
} from '@/shared/assets/chains/logo';
import { type Token } from '@/shared/assets/tokens';
import { usePrevious } from '@/shared/hooks';
import useTracking from '@/shared/hooks/useTracking';
import useSwapRequestStore, {
  type AddressType,
  selectAddressIsValid,
} from '../../hooks/useSwapRequestStore';
import { SwapEvents, type SwapTrackEvents } from '../../types/track';

type AddressInputProps<T extends AddressType> = {
  token: Token;
  setValidator: (name: `${T}Address`, cb: () => boolean) => void;
  type: `${T}`;
};

export default function AddressInput<T extends AddressType>({
  token,
  setValidator,
  type,
}: AddressInputProps<T>): JSX.Element {
  const track = useTracking<SwapTrackEvents>();
  const addressName = `${type}Address` as const;
  const address = useSwapRequestStore((state) => state[addressName]);
  const selector = useMemo(() => selectAddressIsValid(type), []);
  const addressIsValid = useSwapRequestStore(selector);
  const addressChain = token.chain.id;
  const ChainLogo = token.chainflipId
    ? chainflipAssetMonochromaticChainLogo[token.chainflipId]
    : chainTransparentLogo[token.chain.id];
  const setAddress = useSwapRequestStore((state) => state[`set${capitalize(addressName)}`]);

  const [isDirty, setIsDirty] = useState(false);
  const { address: evmWalletAddress } = useAccount();
  const { publicKey: solanaWalletPublicKey } = useWallet();

  const previousEvmWalletAddress = usePrevious(evmWalletAddress);
  const previousSolanaWalletPublicKey = usePrevious(solanaWalletPublicKey);
  const previousChain = usePrevious(addressChain);

  useEffect(() => {
    setValidator(addressName, () => {
      setIsDirty(true);
      return addressIsValid;
    });

    return () => {
      setValidator(addressName, () => true);
    };
  }, [addressIsValid]);

  useEffect(() => {
    if (
      addressChain !== previousChain ||
      (evmWalletAddress !== previousEvmWalletAddress && !isDirty) ||
      (solanaWalletPublicKey !== previousSolanaWalletPublicKey && !isDirty)
    ) {
      // if the chain  or the connected wallet changes, reset the input
      setIsDirty(false);

      // and prefill the value if the wallet address is valid
      if (
        evmWalletAddress &&
        isEvmChain(addressChain) &&
        validateChainAddress(evmWalletAddress)[addressChain]
      ) {
        setAddress(evmWalletAddress);
      } else if (
        solanaWalletPublicKey &&
        isSolanaChain(addressChain) &&
        validateChainAddress(solanaWalletPublicKey.toString())[addressChain]
      ) {
        setAddress(solanaWalletPublicKey.toString());
      } else {
        setAddress('');
      }
    }
  }, [addressChain, evmWalletAddress, solanaWalletPublicKey]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setIsDirty(e.target.value !== '');
    const newValue = e.target.value;
    if (type === 'destination') {
      track(SwapEvents.SelectDestinationAddress, {
        props: { address: newValue },
      });
    }
    if (/^[0-9a-z]*$/i.test(newValue)) {
      setAddress(newValue);
    }
  }, []);

  const showError = isDirty && !addressIsValid;
  if (showError && type === 'destination') {
    track(SwapEvents.SelectDestinationAddressError, {
      props: { address },
    });
  }

  return (
    <div>
      <div
        className={classNames(
          'group relative rounded-md border border-cf-gray-4 bg-cf-gray-3-5 shadow-grayPop3 transition',
          'focus-within:border-cf-gray-5 focus-within:bg-cf-gray-4',
          'hover:border-cf-gray-5 hover:bg-cf-gray-4',
          'active:border-cf-gray-4 active:bg-cf-gray-3-5',
          showError && 'border-cf-red-1',
        )}
      >
        <input
          data-testid={`${type}-address`}
          className="peer z-0 w-full bg-transparent p-3 text-14 text-cf-white outline-none"
          placeholder=""
          onChange={onChange}
          spellCheck={false}
          value={address}
        />
        <div
          className={classNames(
            'pointer-events-none absolute left-0 top-0 flex items-center p-3 text-14 text-cf-light-1 transition',
            'group-focus-within:translate-x-[-5%] group-focus-within:translate-y-[calc(-50%-1px)] group-focus-within:scale-[90%]',
            'peer-[:not(:placeholder-shown)]:translate-x-[-5%] peer-[:not(:placeholder-shown)]:translate-y-[calc(-50%-1px)] peer-[:not(:placeholder-shown)]:scale-[90%]',
          )}
        >
          <div className="flex flex-row items-center gap-1">
            <ChainLogo width={16} height={16} />
            <div>{capitalize(type)} Address</div>
          </div>
          <div
            className={classNames(
              // overlay red border when showing error
              'absolute inset-x-2 bottom-3 top-[49%] -z-10 bg-cf-gray-3-5',
              'transition group-focus-within:bg-cf-gray-4 group-hover:bg-cf-gray-4 group-active:bg-cf-gray-3-5',
            )}
          />
        </div>
      </div>
      {showError && (
        <span className="text-12 text-cf-red-1">
          A valid {token?.chain.name} address is required
        </span>
      )}
    </div>
  );
}
