import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedEffect } from '@/shared/hooks';
import useBoolean from '@/shared/hooks/useBoolean';
import useBoost from './useBoost';
import useSwapRequestStore, { selectShowRouteList } from './useSwapRequestStore';
import { integrationManager, type RouteResponse } from '../integrations';

interface RoutesData {
  routes: RouteResponse[];
  isLoading: boolean;
  refresh: () => void;
}

const RoutesContext = createContext<RoutesData | null>(null);

export const RoutesProvider = ({ children }: { children: React.ReactNode }) => {
  const { value: isLoading, setTrue: onLoadingStart, setFalse: onLoadingEnd } = useBoolean(false);
  const [allRoutes, setAllRoutes] = useState<RouteResponse[]>([]);

  const showRouteList = useSwapRequestStore(selectShowRouteList);
  const srcToken = useSwapRequestStore((state) => state.srcToken);
  const destToken = useSwapRequestStore((state) => state.destToken);
  const srcAmount = useSwapRequestStore((state) => state.srcAmount);

  const { boostActive } = useBoost();

  const refresh = () => {
    if (showRouteList && srcToken && destToken && srcAmount) {
      onLoadingStart();
      integrationManager
        .getRoutes({
          srcChainId: srcToken.chain.id,
          destChainId: destToken.chain.id,
          srcTokenAddress: srcToken.address,
          destTokenAddress: destToken.address,
          amount: BigInt(srcAmount.toString()),
        })
        .then(setAllRoutes)
        .catch((err) => {
          setAllRoutes([]);
          throw err;
        })
        .finally(onLoadingEnd);
    } else {
      setAllRoutes([]);
    }
  };

  const refreshDependencies = [srcToken, destToken, srcAmount];
  useEffect(() => {
    // display loading indicator when params change before debounced function is called
    if (showRouteList) {
      setAllRoutes([]);
      onLoadingStart();
    }
  }, refreshDependencies);
  useDebouncedEffect(refresh, refreshDependencies, 500);

  const displayedRoutes = useMemo(
    () =>
      allRoutes
        .filter((route) => {
          if (route.integration !== 'chainflip') return true;
          if (!boostActive) return !route.integrationData.isBoosted; // dont show boosted route when boost is disabled

          // if user enabled boost, show boosted route or regular route if there is no boost route
          return route.integrationData.isBoosted || !route.integrationData.hasBoostQuote;
        })
        .sort((a, b) => b.destAmount.cmp(a.destAmount)),
    [allRoutes, boostActive],
  );

  const value = useMemo(
    () => ({ routes: displayedRoutes, isLoading, refresh }),
    [displayedRoutes, isLoading, refresh],
  );

  return <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>;
};

const useRoutes = (): RoutesData => {
  const context = useContext(RoutesContext);
  if (context === null) {
    throw new Error('useRoutes must be used within a RoutesProvider');
  }
  return context;
};

export default useRoutes;
