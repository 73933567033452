import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link, Toggle } from '@/shared/components';
import { CloseIcon } from '@/shared/icons/large';
import { SlippageProtectionSection } from './RouteList/SlippageTolerancePopup';
import useSettingsStore from '../../hooks/useSettingsStore';

const Bolt = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="bolt (24px)">
      <path
        id="Vector"
        d="M10.75 13.4639H6.75L13.25 4.96387V10.9639H17.25L10.75 19.4639V13.4639Z"
        fill="#FF33AF"
        fillOpacity="0.4"
        stroke="#FF33AF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default function SettingsModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const { toggleBoostEnabled, boostEnabled } = useSettingsStore();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* backdrop */}
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 z-20 flex w-full items-center justify-center font-aeonikRegular text-white backdrop-blur-[5px]">
            <div className="flex h-full w-full items-center justify-center p-4 md:w-[380px]">
              <Dialog.Panel className="relative w-full rounded-md border border-cf-gray-4 bg-cf-gray-3 shadow-grayPop1">
                <div className="flex flex-col gap-y-5 p-6">
                  <div className="flex items-center">
                    <div className="font-aeonikMedium text-20 text-cf-white">Settings</div>
                    <button
                      data-testid="close-settings"
                      onClick={onClose}
                      type="button"
                      className="ml-auto flex h-6 w-6 items-center justify-center rounded-full text-cf-light-2 outline-none transition hover:bg-cf-gray-5 "
                    >
                      <CloseIcon className="transition hover:text-white" />
                    </button>
                  </div>

                  <div className="border-b border-cf-gray-3-5" />

                  <SlippageProtectionSection open={isOpen} />

                  <div className="border-b border-cf-gray-3-5" />

                  <div className="flex flex-col gap-y-8">
                    <div className="flex flex-col gap-y-1">
                      <div className="flex items-center justify-between font-aeonikMedium text-14 text-cf-white">
                        <div className="flex items-center gap-x-1">
                          <Bolt />
                          Boost (Bitcoin only)
                        </div>
                        <Toggle value={boostEnabled} onToggle={toggleBoostEnabled} />
                      </div>
                      <span className="text-12 text-cf-light-2">
                        Boost speeds up swap times by reducing deposit confirmation times.{' '}
                        <Link
                          href="https://docs.chainflip.io/swapping/intro-to-boost"
                          target="_blank"
                          className="underline"
                        >
                          Learn more
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
