import classNames from 'classnames';
import { toast } from 'sonner';
import { Tooltip } from '@/shared/components';
import useBoolean from '@/shared/hooks/useBoolean';
import { defaultToastClassnames } from '@/shared/hooks/useToast';
import useTracking from '@/shared/hooks/useTracking';
import { BoltIcon } from '@/shared/icons/large';
import { CheckCircleIcon, CloseCircleIcon } from '@/shared/icons/small';
import { chainflipChainMap } from '@/shared/utils';
import Lottie from '@/shared/utils/Lottie';
import boostHoverJson from '@/shared/utils/lotties/boost-hover.json';
import ActiveBoltIcon from './icons/ActiveBolt';
import useBoost from '../../../hooks/useBoost';
import useChainflipBlockConfirmations from '../../../hooks/useChainflipBlockConfirmations';
import useSettingsStore from '../../../hooks/useSettingsStore';
import useSwapRequestStore from '../../../hooks/useSwapRequestStore';
import { SwapEvents, type SwapTrackEvents } from '../../../types/track';

export const BOOST_TOASTER_ID = 'boost';

const BoostToggle = () => {
  const {
    value: boostButtonHover,
    setTrue: enableBoostButtonHover,
    setFalse: disableBoostButtonHover,
  } = useBoolean();
  const toggleBoostEnabled = useSettingsStore((state) => state.toggleBoostEnabled);
  const boostEnabled = useSettingsStore((state) => state.boostEnabled);
  const { boostAvailable, boostActive } = useBoost();
  const track = useTracking<SwapTrackEvents>();
  const srcToken = useSwapRequestStore((state) => state.srcToken);
  const destToken = useSwapRequestStore((state) => state.destToken);
  const selectedRoute = useSwapRequestStore((state) => state.selectedRoute);
  const blockConfirmationData = useChainflipBlockConfirmations(chainflipChainMap.Bitcoin);

  return (
    <Tooltip
      disabled={!boostAvailable}
      content={
        <span className="text-12 text-cf-white">
          {boostActive ? 'Disable' : 'Enable'} <span className="text-cf-pink-1">Boost</span> for
          Bitcoin deposits
        </span>
      }
      disabledContent={
        <span className="text-12 text-cf-white">Boost is available for Bitcoin deposits only</span>
      }
    >
      <button
        data-testid="boost-toggle"
        disabled={!boostAvailable}
        type="button"
        className={classNames(
          'ml-auto rounded-md border border-cf-gray-4 bg-cf-gray-3 p-1 transition duration-150',
          boostAvailable && boostActive && 'cf-boost-active-gradient border-[#49293C]',
          boostAvailable && !boostActive && boostButtonHover && 'border-cf-gray-4 bg-cf-gray-3-5',
        )}
        onPointerEnter={enableBoostButtonHover}
        onPointerLeave={disableBoostButtonHover}
        onClick={() => {
          track(SwapEvents.ToggleBoost, {
            props: {
              assetFrom: srcToken ? `${srcToken.chain.name}.${srcToken.symbol}` : '-',
              assetTo: destToken ? `${destToken.chain.name}.${destToken.symbol}` : '-',
              assetFromAmount: selectedRoute?.srcAmount.toFixed(),
              quotedAmount: selectedRoute?.destAmount.toFixed(),
              enabled: boostEnabled,
            },
          });
          if (boostActive) {
            toast('Boost is disabled for Bitcoin', {
              id: BOOST_TOASTER_ID,
              className: `text-12 ${defaultToastClassnames}`,
              icon: <CloseCircleIcon className="text-cf-red-1" />,
              description: (
                <span className="text-12 font-normal text-cf-light-2">
                  Bitcoin deposits are credited after{' '}
                  {blockConfirmationData.requiredBlockConfirmations} block confirmations
                </span>
              ),
            });
          } else {
            toast('Boost is enabled for Bitcoin', {
              id: BOOST_TOASTER_ID,
              className: `text-12 ${defaultToastClassnames}`,
              icon: <CheckCircleIcon className="text-cf-green-1" />,
              description: (
                <span className="text-12 font-normal text-cf-light-2">
                  Bitcoin deposits are credited after 1 block confirmation
                </span>
              ),
            });
          }
          toggleBoostEnabled();
        }}
      >
        {boostAvailable && boostActive ? (
          <ActiveBoltIcon data-testid="boost-active-indicator" />
        ) : (
          <div className="grid">
            <BoltIcon
              className={classNames(
                !boostAvailable && 'text-cf-gray-5',
                boostAvailable && !boostActive && 'text-cf-light-1',
                'col-start-1 col-end-auto row-start-1 row-end-auto',
              )}
            />
            {boostAvailable && boostButtonHover && (
              <Lottie
                as="span"
                autoplay
                speed={1}
                animationData={boostHoverJson}
                className="col-start-1 col-end-auto row-start-1 row-end-auto"
              />
            )}
          </div>
        )}
      </button>
    </Tooltip>
  );
};

export default BoostToggle;
